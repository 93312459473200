<template>
  <div>
    <a-card :bordered="false">
      <div class="bms-flex-space-between">
        <a-form-model layout="inline">
          <a-form-model-item label="车牌号">
            <a-input v-model="plateNumber" placeholder="请输入车牌号码" />
          </a-form-model-item>
          <a-form-model-item label="通行时间" style="margin-left: 30px">
            <a-range-picker
              @change="onChange"
              :default-value="daterang"
              format="YYYY.MM.DD HH:mm:ss"
              showTime
              separator="-"
              :placeholder="['开始时间', '结束时间']"
              :locale="locale"
            />
            <br />
          </a-form-model-item>
          <a-form-model-item style="margin-left: 15px">
            <a-button type="primary" @click="onQuery()">
              {{ "查询" }}
            </a-button>
          </a-form-model-item>
        </a-form-model>
        <a-button type="primary" @click="download">导出</a-button>
      </div>
    </a-card>
    <a-card :bordered="false" style="margin-top: 10px">
      <a-table
        :columns="columns"
        :data-source="dataObj"
        :pagination="pagination"
        @change="changeTabel"
        :locale="emptyText"
      >
        <a slot="name" slot-scope="text">{{ text }}</a>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import dayjs from "dayjs";
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
import "moment/locale/zh-cn";
const columns = [
  {
    title: "车牌号",
    dataIndex: "plateNumber",
    key: "plateNumber",
  },
  {
    title: "ETC卡号",
    dataIndex: "etcNo",
    key: "etcNo",
  },
  {
    title: "OBU号",
    dataIndex: "obuNo",
    key: "obuNo",
  },
  {
    title: "通行费（元）",
    dataIndex: "transFee",
    key: "transFee",
  },
  {
    title: "入站时间",
    dataIndex: "enTime",
    key: "enTime",
  },
  {
    title: "出站时间",
    dataIndex: "exTime",
    key: "exTime",
  },
  {
    title: "入站地点",
    dataIndex: "enStationName",
    key: "enStationName",
  },
  {
    title: "出站地点",
    dataIndex: "exStationName",
    key: "exStationName",
  },
];
export default {
  name: "index",
  data() {
    return {
      emptyText: { emptyText: "暂无数据" },
      daterang: [],
      locale: locale,
      plateNumber: "", //车牌号
      dataObj: [],
      columns,
      pagination: false,
      searchObj: {
        page: 1,
        pageSize: 10,
      },
      paging: {
        //分页数据
        current: 1,
        defaultPageSize: 10,
      },
    };
  },
  methods: {
    //获取起止时间
    onChange(data, dateString) {
      this.daterang = dateString;
    },
    //获取列表
    start(nubr, en, ex) {
      let self = this;
      if (!nubr) {
        nubr = "";
      }
      if (!en) {
        en = "";
      }
      if (!ex) {
        ex = "";
      }
      this.$http
        .getV(
          "/companyConsume/carList?page=" +
            self.searchObj.page +
            "&pageSize=" +
            self.searchObj.pageSize +
            "&plateNumber=" +
            nubr +
            "&startDate=" +
            en +
            "&endDate=" +
            ex
        )
        .then((res) => {
          if (res.code === 200) {
            if (res.data.rows) {
              res.data.rows.forEach((item) => {
                if (item.enTime == "1900-01-01 00:00:00") {
                  item.enTime = "";
                }
                if (item.exTime == "1900-01-01 00:00:00") {
                  item.exTime = "";
                }
              });
              self.dataObj = res.data.rows;
              self.pagination = { total: parseInt(res.data.total), current: self.searchObj.page };
            }
          }
        });
    },
    //点击查询
    onQuery() {
      this.searchObj.page = 1;
      this.searchObj.pageSize = 10;
      let self = this;
      console.log(self.daterang[0],self.daterang[1]);
      this.start(self.plateNumber, self.daterang[0], self.daterang[1]);
      this.queryStale = true;
    },

    //点击分页
    changeTabel(e) {
      let self = this;
      this.searchObj.page = e.current;
      this.searchObj.pageSize = e.pageSize;

      this.start(self.plateNumber, self.daterang[0], self.daterang[1]);
    },
    //点击下载
    download() {
      let self = this;
      let obj = {
        page: self.searchObj.page,
        pageSize: self.searchObj.pageSize,
        endDate: self.daterang[1],
        plateNumber: self.plateNumber,
        startDate: self.daterang[0],
      };
      this.$http
        .downLoadExcel("/companyConsume/carList/exportExcel", obj)
        .then((res) => {
          console.log(res);
        });
    },
  },
  created() {
    // let beginTime = "1900-01-01 00:00:00";
    let beginTime = dayjs(new Date()).format("YYYY-MM-DD") + " 00:00:00";
    let endTime = dayjs(new Date()).format("YYYY-MM-DD") + " 23:59:59";
    this.daterang = [beginTime, endTime];
    this.start(this.plateNumber, this.daterang[0], this.daterang[1]);
  },
};
</script>

<style scoped>
</style>
